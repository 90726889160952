import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';

const Navbar = (props) => {
    return (
        <AppBar position="static" sx={{backgroundColor: 'white'}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <img src={props.audioInfo.navbarLogo} alt="Logo"
                         style={{height: 40, width: 'auto'}}/>
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default Navbar;
