import 'survey-core/modern.min.css';
import {Model, StylesManager} from 'survey-core';
import {Survey} from 'survey-react-ui';
import {Modal} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import methods from "../api/polls";

export default function Poll() {
    const [modalOpen, setModalOpen] = useState(false);
    // eslint-disable-next-line
    const [surveyId, setSurveyId] = useState(0);
    const [surveyInfo, setSurveyInfo] = useState({});
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {
        let surveyId1 = 0;
        const id = setInterval(() => {
            methods.getCurrentPoll()
                .then(r => {
                    if (!r.data) {
                        setModalOpen(false);
                        return;
                    }
                    if (surveyId1 === r.data.id)
                        return;
                    surveyId1 = r.data.id;
                    setSurveyId(r.data.id);
                    setModalOpen(true);
                    setSurveyInfo(r.data.survey);
                });
        }, 5000);
        return () => clearInterval(id);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    StylesManager.applyTheme("modern");
    const survey = new Model(surveyInfo);
    const surveyComplete = useCallback((sender) => {
        // todo surveyId
        setTimeout(() => setModalOpen(false), 2000);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    survey.onComplete.add(surveyComplete);

    return (
        <div>
            <Modal
                open={modalOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Survey model={survey}/>
                </Box>
            </Modal>
        </div>
    );
}