import React, {useEffect, useState} from "react";
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';

function randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
}

function Splash(props) {
    const [progress, setProgress] = useState(0);
    useEffect(() => {
        if (progress >= 100) {
            props.setSplashActive(false);
        } else
            setTimeout(() => {
                const r = randomIntFromInterval(1, 6);
                setProgress(progress + r);
            }, 100);
    }, [progress, props]);

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{minHeight: '100%'}}
        >
            <Grid item xs={3}>
                <img
                    src="https://trad.suonovivo.com/storage/logos/suonovivo.png"
                    alt="Suonovivo"
                    style={{height: 80, width: 'auto'}}
                />
                <LinearProgress variant="determinate" value={progress}
                                sx={{mt: 2, backgroundColor: "#AAAAAA", height: 10, borderRadius: 5}}/>
            </Grid>
        </Grid>
    );
}

export default Splash;