import * as React from 'react';
import {useState} from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Flag from "./Flag";
import WelcomeText from "./WelcomeText";
import Listening from "./Listening";

const Languages = (props) => {
    const audioInfo = props.audioInfo;
    const [selectedLang, setSelectedLang] = useState("");

    function stopLanguage() {
        setSelectedLang("");
    }

    return (
        <Box position="static">
            <Container maxWidth="xl" sx={{mt: 2}}>
                <Box sx={{display: selectedLang ? 'none' : ''}}>
                    <WelcomeText audioInfo={audioInfo}/>
                    <Container sx={{mx: 'auto'}}>
                        {audioInfo.languages.map(l => (
                            <Box key={l.code} sx={{my: 2}} onClick={() => setSelectedLang(l.code)}>
                                <Flag name={l.code}/>
                            </Box>
                        ))}
                    </Container>
                </Box>
                {selectedLang &&
                    <Listening languageCode={selectedLang} audioInfo={audioInfo} stopLanguage={stopLanguage}/>}
            </Container>
        </Box>
    );
};
export default Languages;
