import {Card, CardActions, CardContent, IconButton} from "@mui/material";
import Typography from "@mui/material/Typography";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import Box from "@mui/material/Box";
import React from "react";
import ReplayIcon from '@mui/icons-material/Replay';
import Grid from "@mui/material/Grid";

export default function OfflineCard(props) {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{minHeight: '100%'}}
        >
            <Grid item xs={3}>
                <Box sx={{width: 275}}>
                    <Card variant="outlined" sx={{borderColor: "red", boxShadow: 3}}>
                        <CardContent>
                            <Typography sx={{fontSize: 24}} color="red" gutterBottom>
                                <SentimentVeryDissatisfiedIcon/>
                            </Typography>
                            <Typography variant="h5">
                                Sei offline! <br/>
                                You are offline!
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Box sx={{width: '100%'}}>
                                <IconButton aria-label="retry" size="large" color="primary" sx={{mx: 'auto'}}>
                                    <ReplayIcon onClick={props.checkIfOnline}/>
                                </IconButton>
                            </Box>
                        </CardActions>
                    </Card>
                </Box>
            </Grid>
        </Grid>
    );
}