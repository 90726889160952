import * as React from 'react';
import {useEffect} from 'react';
import Fade from '@mui/material/Fade';
import Typography from "@mui/material/Typography";

export default function WelcomeText(props) {
    const welcomeTexts = props.audioInfo.languages.map(l => l.WELCOME);
    const [fadeIn, setFadeIn] = React.useState(true);
    const [welcomeIndex, setWelcomeIndex] = React.useState(0);

    useEffect(() => {
        let timeout1, timeout2;
        if (fadeIn)
            timeout1 = setTimeout(() => {
                setFadeIn(false);
            }, 2000);
        else {
            timeout2 = setTimeout(() => {
                if (welcomeTexts[welcomeIndex + 1]) {
                    setWelcomeIndex(welcomeIndex + 1);
                } else {
                    setWelcomeIndex(0);
                }
                setFadeIn(true);
            }, 300);
        }

        return () => {
            if (timeout1) clearTimeout(timeout1);
            if (timeout2) clearTimeout(timeout2);
        };
    }, [fadeIn, welcomeTexts, welcomeIndex]);

    return (
        <Fade in={fadeIn}>
            <Typography
                variant="h5"
                align='center'
                sx={{fontWeight: 'bolder', lineHeight: 1.2}}
            >
                {welcomeTexts[welcomeIndex]}
            </Typography>
        </Fade>
    );
}
