import * as React from 'react';
import {useEffect} from 'react';
import {useSnackbar} from 'notistack'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Listening = (props) => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const rememberEarphonesText = props.audioInfo.languages
        .find(l => l.code === props.languageCode)?.REMEMBER_EARPHONES;
    const listeningLangText = props.audioInfo.languages
        .find(l => l.code === props.languageCode)?.LISTENING_LANG;
    useEffect(() => {
        enqueueSnackbar(rememberEarphonesText, {
            variant: 'warning',
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center'
            },
            autoHideDuration: 3000
        });

        return () => {
            closeSnackbar();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Box>
            <Typography
                variant="h5"
                align='center'
                sx={{lineHeight: 1.2}}
            >
                {listeningLangText}
            </Typography>
            <Box sx={{mt: 4}} onClick={() => props.stopLanguage()}>
                <img src='/audio_stop.png' alt='Stop' style={{width: 100, height: 'auto'}}/>
            </Box>
        </Box>
    )

};
export default Listening;
