import './App.css';
import React, {useEffect, useState} from "react";
import Box from '@mui/material/Box';
import Navbar from './components/Navbar';
import Languages from './components/Languages';
import Splash from './components/Splash';
import StickyFooter from './components/StickyFooter';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import methods from "./api/audioInfo";
import {SnackbarProvider} from 'notistack'
import Poll from "./components/Poll";
import OfflineCard from "./components/OfflineCard";

const theme = createTheme({
    typography: {
        fontFamily: [
            'Nunito',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif'
        ].join(','),
    }
});

function App() {
    const [splashActive, setSplashActive] = useState(true);
    const [audioInfo, setAudioInfo] = useState({languages: []});
    const [offline, setOffline] = useState(false);

    useEffect(() => {
        methods.getAudioInfo().then((r) => {
            setAudioInfo(r.data);
        });
        checkIfOnline();
    }, []);
    useEffect(() => {
        setSplashActive(splashActive);
    }, [splashActive]);

    function checkIfOnline() {
        setOffline(!navigator.onLine);
    }

    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider>
                <Box className="App"
                     sx={{
                         display: 'flex',
                         flexDirection: 'column',
                         minHeight: '100%',
                     }}>
                    {splashActive ? (
                        <Splash audioInfo={audioInfo} setSplashActive={setSplashActive}/>
                    ) : (
                        <>
                            {offline ? (
                                <OfflineCard checkIfOnline={checkIfOnline}/>
                            ) : (
                                <>
                                    <Navbar audioInfo={audioInfo}/>
                                    <Languages audioInfo={audioInfo}/>
                                    <Poll/>
                                    <StickyFooter/>
                                </>
                            )}
                        </>
                    )}
                </Box>
            </SnackbarProvider>
        </ThemeProvider>
    );
}

export default App;
